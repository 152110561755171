import * as React from 'react'
// components
import Header from '../components/layouts/Header'
import Footer from '../components/layouts/Footer'
import NSF from '../components/sections/NSF'

const NsfOhioProject = () => {
  return (
    <>
      <div id='smooth-wrapper'>
        <div id='smooth-content'>
          <div className='podio'>
            <NSF />
            <Footer />
          </div>
        </div>
      </div>
      <Header />
    </>
  )
}

export default NsfOhioProject
