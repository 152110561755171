import * as React from 'react'
import './styles.css'
// assets
import Glow from '../../glows'
import NSFImage from '../../../assets/imgs/nsf/logo.png'

const NSF = () => {
  return (
    <section className='nsf-section'>
      <div className='box'>
        <div className='nsf-container'>
          <img src={NSFImage} className='nsf-logo' alt='National Science Foundation logo'/>
          <h1 className='nsf-main-title'>Unlocking Workforce Intelligence</h1>
          <h2 className='nsf-sub-title'>
            AI-Powered Skills Mapping and Regional Strategy for Emerging
            Industries
          </h2>
          <a
            href='../../../assets/reports/nsf/nsf.pdf'
            download='Unlocking Workforce Intelligence'
            className='nsf-button'
          >
            Download Report
          </a>
          <Glow />
          <Glow position='right' />
        </div>
      </div>
    </section>
  )
}

export default NSF
